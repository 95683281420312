import React from 'react';
//import { Table } from 'reactstrap';
import {
  //Link,
  //SmallH2,
  Section
} from '../components/utils.js';


class Stat547UIndex extends React.Component {
  render() {
    return (
      <div className="col-12">
        <Section>
          <p>More info coming soon.</p>
        </Section>
      </div>
    );
  }
}

export default Stat547UIndex;
